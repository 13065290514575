export default function modalForms() {
  const system = document.getElementsByClassName('system-name');
  const subject = document.getElementsByClassName('subject-name');
  const bodyEl = document.querySelector("body");

  function openModal() {
    let modalTrigger = document.querySelectorAll('.modal-trigger');
    
    modalTrigger.forEach(function (trigger) {
      trigger.addEventListener('click', function (event) {
  
        const fullHref = this.firstChild.getAttribute('href').substr(1);
        const target = fullHref.split('-')[0];
        const systemName = fullHref.split('-')[1];

        const modalWindow = document.getElementsByClassName('modal-' + target)[0];
        

        if (system.length) {
          for(var i = 0; i < system.length; i++){
            system[i].innerHTML = "system " + systemName;
          }
        }

        if (subject.length) {
          for(var i = 0; i < subject.length; i++){
            subject[i].value = "system " + systemName;
          }
        }
  
        if (modalWindow.classList) {
          modalWindow.classList.add('open');
          bodyEl.classList.add("no-scrollable");
        }

        event.preventDefault();
      });
    });
  }
  
  function closeModal() {
    let closeBtns = document.querySelectorAll('.modal-close');
    let modalOverlays = document.querySelectorAll('.modal-overlay');

    closeBtns.forEach(function (btn) {
      btn.addEventListener('click', function (event) {
        const modalWindow = this.parentNode.parentNode;

        if (system.length) {
          for(var i = 0; i < system.length; i++){
            system[i].innerHTML =  "";
          }
        }

        if (subject.length) {
          for(var i = 0; i < subject.length; i++){
            subject[i].value =  "";
          }
        }

        modalWindow.classList.remove('open');
        bodyEl.classList.remove("no-scrollable");
      });
    });
  
    modalOverlays.forEach(function (overlay) {
      const modalWindow = overlay.parentNode;
  
      overlay.addEventListener('click', function () {

      if (system.length) {
        for(var i = 0; i < system.length; i++){
          system[i].innerHTML =  "";
        }
      }

      if (subject.length) {
        for(var i = 0; i < subject.length; i++){
          subject[i].value =  "";
        }
      }

      modalWindow.classList.remove('open');
      bodyEl.classList.remove("no-scrollable");
      });
    });
  }
  openModal();
  closeModal();
};

document.addEventListener("DOMContentLoaded", () => {
  modalForms();
});
